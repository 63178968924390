// stylelint-disable declaration-no-important

//
// Visibility utilities
//
.visible {
	visibility: visible !important;
}

.invisible {
	visibility: hidden !important;
}
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		.visible#{$infix} {
		  visibility: visible !important;
		}

		.invisible#{$infix} {
		  visibility: hidden !important;
		}

	}
}
