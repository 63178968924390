// stylelint-disable property-disallowed-list, declaration-no-important

//
// Border
//


@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix}         { border: $border-width solid $border-color !important; }
    .border#{$infix}-0        { border: 0 !important; }
    @each $value in $edges {
      .border#{$infix}-#{$value} { border-#{$value}: $border-width solid $border-color !important; }
      .border#{$infix}-#{$value}-0 { border-#{$value}: 0 !important; }
    }
  }
}


@each $color, $value in $theme-colors {
  .border-#{$color} {
    border-color: $value !important;
  }
}

.border-white {
  border-color: $white !important;
}

//
// Border-radius
//

.rounded-sm {
  border-radius: $border-radius-sm !important;
}

.rounded {
  border-radius: $border-radius !important;
}

.rounded-top {
  border-top-left-radius: $border-radius !important;
  border-top-right-radius: $border-radius !important;
}

.rounded-right {
  border-top-right-radius: $border-radius !important;
  border-bottom-right-radius: $border-radius !important;
}

.rounded-bottom {
  border-bottom-right-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}

.rounded-left {
  border-top-left-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}

.rounded-lg {
  border-radius: $border-radius-lg !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: $rounded-pill !important;
}

.rounded-0 {
  border-radius: 0 !important;
}
